import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Clients.css'

const Clients = () => {
  // Replace with actual client logo/image URLs
  const clients = [
    { id: 1, name: "" },
    { id: 1, name: "", logo : "https://partner.microsoft.com/-/media/mssc/mpn/partner/marketing/usisvshowcase/dunbradstreet.jpg?h=260&iar=0&w=500&hash=36321D6E28BEC3A978E9F1F01DD5BAC0" },
    { id: 2, name: "", logo: "https://alaintesting.com.my/wp-content/uploads/2023/05/SSM-reg-no.-2015010036161128948-W-1.png" },
    { id: 3, name: "ONEBLOC SDN. BHD." },
    { id: 4, name: "", logo: "https://upload.wikimedia.org/wikipedia/en/c/c2/University_of_Agricultural_Science_Raichuru_logo.jpg" },
    { id: 5, name: "", logo: "https://www.reshmihospital.com/static/media/reshmi-logo.1f098df7c6b29459af63.jpeg" },
    { id: 6, name: "", logo: "https://rathihospital.in/static/media/logo.31face54f1f9417d8749.png" },
    { id: 7, name: "", logo: "https://shreevidyacollege.in/static/media/srividyaLogo.510045784e9c86ba85d6.jpg" },
  ];

  return (
    <div style={{ padding: "0px" }}>
        <div className='clientSection'>
          <div className='heading'>
              <h1>Our Cliens</h1>
          </div>
          <div className="content">
            <h2>We're a trusted partner for some of the world's leading organizations.</h2>
          </div>  
        </div>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        style={{ padding: "20px 0" }}
      >
        {clients.map((client) => (
          <SwiperSlide key={client.id}>
            <div className="client-slide">
            {client.id !== 3 && (
              <img
                src={client.logo}
                alt={client.name}
                style={{ width: "50%", borderRadius: "10px" }}
              />
            )}
              <p style={{ textAlign: "center", marginTop: "25px", fontWeight:"bold", color:"purple" }}>
                {client.name}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Clients;